import React from "react";
import { useModalContext } from "../../../global/modals";
import { cn } from "../../../../helpers/cn";
import { DefaultModal } from "../../../global/modals/material/DefaultModal";
import { LibraryGetTheAppModal } from "../../../library/libraryTrainings/LibraryGetTheAppModal";
import { LibraryGetTheAppBottomSheet } from "../../../library/libraryTrainings/LibraryGetTheAppBottomSheet";

type LibraryCardWrapperProps = React.ComponentProps<"div">;

export const LibraryCardWrapper = ({
  className,
  children,
  ...props
}: LibraryCardWrapperProps) => {
  const { showModal, closeModal } = useModalContext();

  const modalHandler = () => {
    showModal(
      <>
        <DefaultModal onClose={closeModal}>
          <LibraryGetTheAppModal />
          <LibraryGetTheAppBottomSheet />
        </DefaultModal>
      </>,
    );
  };

  return (
    <div
      className={cn("cursor-pointer", className)}
      onClick={modalHandler}
      {...props}
    >
      {children}
    </div>
  );
};
