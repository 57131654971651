import QRCodeStyling from "qr-code-styling";

type GenerateQrCode = {
  data: string;
  image?: string;
};

export const generateQrCode = ({
  data,
  image,
}: GenerateQrCode): QRCodeStyling => {
  try {
    const qrCode = new QRCodeStyling({
      width: 250,
      height: 250,
      data: data,
      image: image,
      backgroundOptions: {
        color: "transparent",
      },
      imageOptions: {
        margin: 8.0,
      },
    });

    return qrCode;
  } catch (error) {
    console.error(error);
  }
};
