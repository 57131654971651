import { getRemoteConfig, RemoteConfig } from "firebase/remote-config";
import { app } from "./firebaseClient";

let remoteConfig: RemoteConfig;
if (typeof window !== "undefined") {
  remoteConfig = getRemoteConfig(app);

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 100000,
    fetchTimeoutMillis: 20000,
  };
}

export { remoteConfig };
