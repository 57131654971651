import React from "react";
import { Card } from "@mui/material";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

export const ListingModalQrCodeWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Card
      sx={{
        backgroundColor: colors.primary.primary,
        borderRadius: "16px",
        padding: "1rem",
        display: "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "@media (min-width: 1240px)": {
          display: "flex",
        },
      }}
    >
      {children}
    </Card>
  );
};
