import React from "react";
import { cn } from "../../../../../helpers/cn";

type StoresLinksWrapperProps = React.ComponentProps<"div">;

export const StoresLinksWrapper = ({
  className,
  children,
  ...props
}: StoresLinksWrapperProps) => {
  return (
    <div
      className={cn(
        "flex flex-row justify-center items-center gap-4 md:justify-start lg:flex-col",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
