import React from "react";
import { LibraryUserTrainings } from "./LibraryUserTrainings";
import { LibraryWorkouts } from "./LibraryWorkouts";
import { LibraryRecommendedTrainingPlans } from "./LibraryRecommendedTrainingPlans";
import { LibrarySavedListings } from "./LibrarySavedListings";
import { LibrarySavedResources } from "./LibrarySavedResources";
import { LibraryGetTheApp } from "../libraryGetTheApp/LibraryGetTheApp";
import { LibraryTrainingProvider } from "./LibraryTrainingProvider";
import { LibraryTrainingsWrapper } from "./LibraryTrainingsWrapper";

export const LibraryTrainings = () => {
  return (
    <LibraryTrainingProvider>
      <LibraryTrainingsWrapper>
        <LibraryUserTrainings />
        <LibraryWorkouts />
        <LibraryGetTheApp />
        <LibraryRecommendedTrainingPlans />
        <LibrarySavedListings />
        <LibrarySavedResources />
      </LibraryTrainingsWrapper>
    </LibraryTrainingProvider>
  );
};
