import { Typography } from "@mui/material";
import {
  MaterialFooterTitle,
  MaterialFooterWrapper,
} from "../../global/layout/material/materialFooter";
import { useModalContext } from "../../global/modals";
import { AppTextButton } from "../../global/ui/buttons";
import MobileStores from "../../global/MobileStores/MobileStores";

export const LibraryGetTheAppBottomSheet = () => {
  const { closeModal } = useModalContext();

  return (
    <div className={"lg:hidden h-54 pt-2 px-4 flex flex-col items-center"}>
      <MaterialFooterWrapper className={"pt-4"}>
        <MaterialFooterTitle>Get the app</MaterialFooterTitle>

        <MobileStores useWhiteIcons={true} />
      </MaterialFooterWrapper>

      <Typography className={"text-center mt-2"}>
        Access this workout & more for free on the Pod1um App
      </Typography>

      <AppTextButton onClick={closeModal} className={"mt-4"}>
        Close
      </AppTextButton>
    </div>
  );
};
