import React from "react";
import { cn } from "../../../helpers/cn";

type LibraryTrainingsWrapperProps = React.ComponentProps<"div">;

export const LibraryTrainingsWrapper = ({
  className,
  children,
  ...props
}: LibraryTrainingsWrapperProps) => {
  return (
    <div className={cn("flex flex-col pt-6 space-y-6", className)} {...props}>
      {children}
    </div>
  );
};
