import { useFirebaseContext } from "../../components/firebase";
import { useEffect, useState } from "react";
import { Value } from "@firebase/remote-config";

type DynamicLinks = {
  coach?: string;
  admin?: string;
  athlete?: string;
};

export const useGetCurrentQrCodeUrl = () => {
  const { getRemoteConfigValue } = useFirebaseContext();
  const [currentQrCodeUrl, setCurrentQrCodeUrl] = useState<string>("");

  useEffect(() => {
    const remoteConfigValue = async () => {
      const value: Value = await getRemoteConfigValue({ key: "dynamic_links" });
      const parsedValue = JSON.parse(value.asString()) as DynamicLinks;
      setCurrentQrCodeUrl(parsedValue.athlete);
    };

    void remoteConfigValue();
  }, []);

  return currentQrCodeUrl;
};
