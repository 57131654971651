import { Card, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import Link from "next/link";
import { buildUrlName } from "../../../../../lib/generic";
import { ExploreCoachCardCoverImage } from "./ExploreCoachCardCoverImage";
import { ExploreCoachCardAvatarImage } from "./ExploreCoachCardAvatarImage";
import { CustomChip } from "../../../../global/chips/CustomChip";
import { MaterialCardScore } from "../MaterialCardScore";
import { useRouter } from "next/router";
import { getLastOrganisation } from "../../../../../lib/specialist/getLastOrganisation";
import { IUserData } from "../../../../../interfaces/user/IUserData";
import {
  BadgeVerificationContainer,
  BadgeVerificationIcon,
} from "../../../../global/badgeVerification";
import { verificationBadgeColor } from "../../../../../lib/specialist/verificationBadgeColor";
import React from "react";

export interface ExploreCoachCardProps extends React.ComponentProps<"div"> {
  data: IUserData;
}

export const ExploreCoachCard = ({ data, ...props }: ExploreCoachCardProps) => {
  const theme = useSelector((state: RootState) => state.theme);
  const lastOrganisation = getLastOrganisation(data);
  const router = useRouter();
  const isDarkBackground = router.asPath === "";

  return (
    <div {...props}>
      <Link
        href={{
          pathname: "/specialist/[id]/[name]",
          query: {
            id: data.id,
            name: buildUrlName(data?.name),
          },
        }}
      >
        <Card
          className="space-y-[64px] pb-[20px] rounded-[16px]"
          sx={{
            backgroundColor: isDarkBackground
              ? theme.componentColors.surface.surfaceDim
              : theme.componentColors.surface.surfContainer,
            backgroundImage: "none",
          }}
        >
          <Stack className="relative">
            <ExploreCoachCardCoverImage imageUrl={data.coverUrl} />
            <Stack className="absolute transform  -translate-x-1/2 -translate-y-1/2 left-1/2 -bottom-[100px] lg:-bottom-[110px]">
              <ExploreCoachCardAvatarImage data={data} />
              {data.verificationBadge && (
                <BadgeVerificationContainer
                  className={"absolute bottom-0 right-0 z-20"}
                >
                  <BadgeVerificationIcon
                    sx={{
                      color: verificationBadgeColor({
                        isFromSpecialist: true,
                        isFromTeam: false,
                        type: data.verificationBadge,
                      }),
                    }}
                  />
                </BadgeVerificationContainer>
              )}
            </Stack>
          </Stack>
          <Stack className="space-y-[12px]">
            <Stack className="flex flex-col items-center justify-center ">
              <Stack direction="row" className="space-x-[12px]">
                <Typography
                  sx={{
                    color: theme.componentColors.surface.surfaceFull,
                  }}
                  className="text-[20px] font-medium  px-[2px]"
                >
                  {data.name}
                </Typography>

                <MaterialCardScore score={data.score} />
              </Stack>

              {lastOrganisation && (
                <Typography
                  sx={{
                    color: theme.componentColors.surface.onSurface,
                  }}
                  className="font-normal text-[14px]"
                >
                  {lastOrganisation.name}
                </Typography>
              )}
            </Stack>

            <Stack
              direction="row"
              className="flex items-center justify-center space-x-[8px]"
            >
              {data.sports?.map((item, i) => {
                if (i < 3 && item.title) {
                  return (
                    <CustomChip
                      label={`${item.title}`}
                      key={i}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        void router.push("/explore");
                      }}
                    />
                  );
                }
              })}
            </Stack>
          </Stack>
        </Card>
      </Link>
    </div>
  );
};
