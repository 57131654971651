import React from "react";
import { useLibraryTrainingsData } from "../../../hooks/library/useLibraryTrainingsData";

export const LibraryTrainingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useLibraryTrainingsData();

  return <>{children}</>;
};
