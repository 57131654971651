import { useGetCurrentQrCodeUrl } from "../../../../../hooks/firebase";
import { generateQrCode } from "../../../../../lib/qrCode";
import { useEffect, useRef } from "react";

export const ListingModalQrCodeImg = () => {
  const qrCodeUrl = useGetCurrentQrCodeUrl() ?? "";
  const qrCodeRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const getQrCode = () => {
      const currentGeneratedQrCode = generateQrCode({
        data: qrCodeUrl,
        image: "/images/pod1um-black-logo.png",
      });
      currentGeneratedQrCode.append(qrCodeRef.current);
    };

    void getQrCode();
  }, [qrCodeUrl]);

  return (
    <div className={"h-[15.7rem] w-[15.7rem]"}>
      <div ref={qrCodeRef} />
    </div>
  );
};
