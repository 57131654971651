import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SliderLoading } from "../../global/sliders/slider/sliderLoading";
import {
  Slider,
  SliderButton,
  SliderButtonPropsDirection,
  SliderHeaderWrapper,
  SliderTitleVariantOne,
  SliderWrapper,
} from "../../global/sliders";
import Link from "next/link";
import { AppTextButton } from "../../global/ui/buttons";
import { setTabSection } from "../../../redux/pages/exploreSlice";
import { SwiperSlide } from "swiper/react";
import { MaterialCard } from "../../explore/material/cards/MaterialCard";

export const LibraryWorkouts = () => {
  const { workouts, isLoading } = useSelector(
    (state: RootState) => state.library.trainingSection,
  );
  const dispatch = useDispatch();
  const libraryWorkoutsSwiperId = "user-workouts";

  if (isLoading) {
    return <SliderLoading />;
  }

  if (!isLoading && workouts.length > 0) {
    return (
      <SliderWrapper>
        <SliderHeaderWrapper>
          <SliderTitleVariantOne>Free Workouts</SliderTitleVariantOne>
          <Link
            href={"/explore"}
            onClick={() => {
              dispatch(setTabSection(2));
            }}
          >
            <AppTextButton>See All</AppTextButton>
          </Link>

          <SliderButton
            id={libraryWorkoutsSwiperId}
            middlePosition={true}
            direction={SliderButtonPropsDirection.LEFT}
          />

          <SliderButton
            id={libraryWorkoutsSwiperId}
            middlePosition={true}
            direction={SliderButtonPropsDirection.RIGHT}
          />
        </SliderHeaderWrapper>

        <Slider customSwiperId={libraryWorkoutsSwiperId}>
          {workouts.map((workout, i) => {
            return (
              <SwiperSlide key={i}>
                <MaterialCard data={workout} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </SliderWrapper>
    );
  }
};
