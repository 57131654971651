import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SliderLoading } from "../../global/sliders/slider/sliderLoading";
import {
  Slider,
  SliderButton,
  SliderButtonPropsDirection,
  SliderButtonsWrapper,
  SliderHeaderTextWrapper,
  SliderHeaderWrapper,
  SliderTitle,
  SliderWrapper,
} from "../../global/sliders";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { LibraryCard } from "../../explore/material/cards";

export const LibraryUserTrainings = () => {
  const { userTrainingPlans, isLoading } = useSelector(
    (state: RootState) => state.library.trainingSection,
  );
  const libraryUserTrainingsSwiperId = "user-training-plans";

  if (isLoading) {
    return <SliderLoading />;
  }

  if (!isLoading && userTrainingPlans.length > 0) {
    return (
      <SliderWrapper>
        <SliderHeaderWrapper>
          <SliderHeaderTextWrapper>
            <SliderTitle>My Training Plans</SliderTitle>
          </SliderHeaderTextWrapper>

          <SliderButtonsWrapper>
            <SliderButton
              id={libraryUserTrainingsSwiperId}
              middlePosition={false}
              direction={SliderButtonPropsDirection.LEFT}
            />

            <SliderButton
              id={libraryUserTrainingsSwiperId}
              middlePosition={false}
              direction={SliderButtonPropsDirection.RIGHT}
            />
          </SliderButtonsWrapper>
        </SliderHeaderWrapper>

        <Slider customSwiperId={libraryUserTrainingsSwiperId}>
          {userTrainingPlans.map((userTrainingPlan, i) => {
            return (
              <SwiperSlide key={i}>
                <LibraryCard listing={userTrainingPlan} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </SliderWrapper>
    );
  }
};
