import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SliderLoading } from "../../global/sliders/slider/sliderLoading";
import {
  Slider,
  SliderButton,
  SliderButtonPropsDirection,
  SliderButtonsWrapper,
  SliderHeaderWrapper,
  SliderTitleVariantOne,
  SliderWrapper,
} from "../../global/sliders";
import { MaterialCard } from "../../explore/material/cards/MaterialCard";
import { SwiperSlide } from "swiper/react";

export const LibrarySavedResources = () => {
  const { bookmarkedTrainingAndWorkouts, isLoading } = useSelector(
    (state: RootState) => state.library.trainingSection,
  );
  const resources = bookmarkedTrainingAndWorkouts.filter(
    (item) => item.type === "assessment",
  );
  const librarySavedResourcesSwiperId = "library-workouts";

  if (isLoading) {
    return <SliderLoading />;
  }

  if (!isLoading && resources.length > 0) {
    return (
      <SliderWrapper>
        <SliderHeaderWrapper>
          <SliderTitleVariantOne>Bookmarked Resources</SliderTitleVariantOne>

          <SliderButtonsWrapper>
            <SliderButton
              id={librarySavedResourcesSwiperId}
              middlePosition={false}
              direction={SliderButtonPropsDirection.LEFT}
            />

            <SliderButton
              id={librarySavedResourcesSwiperId}
              middlePosition={false}
              direction={SliderButtonPropsDirection.RIGHT}
            />
          </SliderButtonsWrapper>
        </SliderHeaderWrapper>

        <Slider customSwiperId={librarySavedResourcesSwiperId}>
          {bookmarkedTrainingAndWorkouts.map((bookmarkedListing, i) => {
            return (
              <SwiperSlide key={i}>
                <MaterialCard data={bookmarkedListing} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </SliderWrapper>
    );
  }
};
