import { TitleLarge } from "../../../../global/typography";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { TypographyProps } from "@mui/material";
import { useRouter } from "next/router";

type ListingModalQrCodeTitleProps = TypographyProps;

export const ListingModalQrCodeTitle = ({
  className,
}: ListingModalQrCodeTitleProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);
  const router = useRouter();
  const isOnLibrary = router.asPath === "/";

  return (
    <TitleLarge
      sx={{
        color: colors.primary.onPrimary,
        textAlign: "center",
      }}
      className={className}
    >
      Access this {isOnLibrary ? "training plan" : "workout"} & more for free on
      the Pod1um App
    </TitleLarge>
  );
};
