import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Chip } from "@mui/material";
import React from "react";

type CustomChipProps = React.ComponentProps<typeof Chip>;

export const CustomChip = ({
  label,
  icon,
  onClick,
  ...props
}: CustomChipProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );

  return (
    <Chip
      sx={{
        backgroundColor: componentColors.secondary.onSecondary,
        color: componentColors.secondary.onSecondaryContainer,
      }}
      label={label}
      icon={icon}
      onClick={onClick}
      {...props}
    />
  );
};
