import { FirebaseProvider } from "../../firebase";
import {
  ListingModalQrCodeTitle,
  ListingModalQrCodeWrapper,
} from "../../training-plan/material/listingModal/listingModalQrCode";
import { AppIconButton } from "../../global/ui/buttons";
import { CloseOutlined } from "@mui/icons-material";
import { ListingModalQrCodeImg } from "../../training-plan/material/listingModal/listingModalQrCode/ListingModalQrCodeImg";
import React from "react";
import { useModalContext } from "../../global/modals";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const LibraryGetTheAppModal = () => {
  const { closeModal } = useModalContext();
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  return (
    <div className={"hidden lg:block"}>
      <FirebaseProvider>
        <ListingModalQrCodeWrapper>
          <AppIconButton
            onClick={closeModal}
            className={"absolute top-4 right-4 hidden lg:flex"}
          >
            <CloseOutlined
              sx={{
                color: componentColors.primary.onPrimary,
              }}
            />
          </AppIconButton>
          <ListingModalQrCodeImg />
          <ListingModalQrCodeTitle className={"mt-4"} />
        </ListingModalQrCodeWrapper>
      </FirebaseProvider>
    </div>
  );
};
