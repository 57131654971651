import {
  ActionButton,
  ActionButtonContainer,
  LandingHeroContent,
  LandingHeroImage,
  LandingHeroImagesMobileRoot,
  LandingHeroRoot,
  LandingHeroSubtitle,
  LandingHeroTexts,
  LandingHeroTextsActionsContainer,
  LandingHeroTitle,
} from "../../LandingHeroSection";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { setTabSection } from "../../../../redux/pages/exploreSlice";
import { LandingHeroImagesWebRoot } from "../../LandingHeroSection/LandingHeroImages/LandingHeroImagesWebRoot";
import MobileStores from "../../../global/MobileStores/MobileStores";
import { setRegisterModal } from "../../../../redux/pages/registerPageSlice";

export const AthleteHeroSection = () => {
  const dispatch = useDispatch();

  const handleExplore = () => {
    dispatch(setTabSection(1));
  };

  return (
    <LandingHeroRoot>
      <LandingHeroContent>
        <LandingHeroTextsActionsContainer className="gap-10">
          <LandingHeroTexts className="gap-2 md_2:gap-6">
            <LandingHeroTitle className="leading-[3rem] md_2:leading-[5rem]">
              Optimise preparation, <br /> maximize performance
            </LandingHeroTitle>
            <LandingHeroSubtitle className="text-sm ">
              Get access to coaches, plans & programs proven at the highest
              level.
            </LandingHeroSubtitle>
          </LandingHeroTexts>

          <ActionButtonContainer>
            <ActionButton
              variant="primary"
              title="Sign up free"
              className="md_2:w-56"
              onClick={() => {
                dispatch(setRegisterModal());
              }}
            />

            <Link href="explore">
              <ActionButton
                variant="secondary"
                title="Explore"
                onClick={handleExplore}
                className="md_2:w-56"
              />
            </Link>
          </ActionButtonContainer>
        </LandingHeroTextsActionsContainer>

        <MobileStores useWhiteIcons={true} className={"mx-auto lg:mx-0"} />
      </LandingHeroContent>

      <LandingHeroImagesWebRoot>
        <>
          <div className="pt-20 w-[96%]">
            <LandingHeroImage
              width={478}
              height={975}
              src={"/images/coach-landing-phone-2.png"}
              alt="Coach landing page phone 2 | Pod1um"
            />
          </div>
          <div className="pt-5 w-[96%]">
            <LandingHeroImage
              width={478}
              height={975}
              src={"/images/coach-landing-phone-1.png"}
              alt="Coach landing page phone 1 | Pod1um"
              goDown={false}
            />
          </div>
        </>
      </LandingHeroImagesWebRoot>

      <LandingHeroImagesMobileRoot>
        <LandingHeroImage
          src="/images/coach-landing-phone-1.png"
          alt="Coach landing page featured phones | Pod1um"
          className="mx-auto w-[20rem] shadow-transparent rounded-none"
        />
      </LandingHeroImagesMobileRoot>
    </LandingHeroRoot>
  );
};
