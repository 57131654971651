import React, { createContext, useContext } from "react";
import { fetchAndActivate, getValue, Value } from "@firebase/remote-config";
import { remoteConfig } from "../../lib/firebase";

type GetRemoteConfigValue = {
  key: string;
};

type FirebaseContextProps = {
  getRemoteConfigValue: (props: GetRemoteConfigValue) => Promise<Value>;
};

const FirebaseContext = createContext<FirebaseContextProps | undefined>(
  undefined,
);

const getRemoteConfigValue = async ({
  key,
}: GetRemoteConfigValue): Promise<Value | null> => {
  try {
    await fetchAndActivate(remoteConfig);
    const value = getValue(remoteConfig, key);
    return value || null;
  } catch (error) {
    console.error("Failed to fetch remote config", error);
    return null;
  }
};

const firebaseContextProps: FirebaseContextProps = {
  getRemoteConfigValue,
};

export const FirebaseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FirebaseContext.Provider value={firebaseContextProps}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error(
      "useFirebaseContext must be used within a FirebaseProvider",
    );
  }

  return context;
};
