import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SliderLoading } from "../../global/sliders/slider/sliderLoading";
import {
  Slider,
  SliderButton,
  SliderButtonPropsDirection,
  SliderButtonsWrapper,
  SliderHeaderWrapper,
  SliderTitleVariantOne,
  SliderWrapper,
} from "../../global/sliders";
import { SwiperSlide } from "swiper/react";
import { MaterialCard } from "../../explore/material/cards/MaterialCard";

export const LibraryRecommendedTrainingPlans = () => {
  const { recommendedTrainingPlans, isLoading } = useSelector(
    (state: RootState) => state.library.trainingSection,
  );
  const libraryRecommendedListingsSwiperId = "recommended-listings";
  const dispatch = useDispatch();

  if (isLoading) {
    return <SliderLoading />;
  }
  if (!isLoading && recommendedTrainingPlans.length > 0) {
    return (
      <SliderWrapper>
        <SliderHeaderWrapper>
          <SliderTitleVariantOne>
            Recommended Training Plans
          </SliderTitleVariantOne>

          <SliderButtonsWrapper>
            <SliderButton
              id={libraryRecommendedListingsSwiperId}
              middlePosition={false}
              direction={SliderButtonPropsDirection.LEFT}
            />

            <SliderButton
              id={libraryRecommendedListingsSwiperId}
              middlePosition={false}
              direction={SliderButtonPropsDirection.RIGHT}
            />
          </SliderButtonsWrapper>
        </SliderHeaderWrapper>

        <Slider customSwiperId={libraryRecommendedListingsSwiperId}>
          {recommendedTrainingPlans.map((recommendedListing, i) => {
            return (
              <SwiperSlide key={i}>
                <MaterialCard data={recommendedListing} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </SliderWrapper>
    );
  }
};
