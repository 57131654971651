import { Typography, TypographyProps } from "@mui/material";

export type TitleLargeProps = TypographyProps;

export const TitleLarge = ({
  sx,
  className,
  children,
  variant,
  ...props
}: TitleLargeProps) => {
  return (
    <Typography
      sx={{
        fontSize: "1.375rem",
        lineHeight: "1.75rem",
        fontWeight: 400,
        ...sx,
      }}
      variant={variant}
      className={className}
      {...props}
    >
      {children}
    </Typography>
  );
};
