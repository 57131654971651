import { SliderLoadingWrapper } from "./SliderLoadingWrapper";
import { DefaultLoadingComponent } from "../../../defaultLoading/DefaultLoadingComponent";
import { SliderLoadingContentWrapper } from "./SliderLoadingContentWrapper";

export const SliderLoading = () => {
  const className = "w-64 h-44 lg:w-80 lg:h-56 ";

  return (
    <SliderLoadingWrapper>
      <DefaultLoadingComponent width={250} height={32} />

      <SliderLoadingContentWrapper>
        <DefaultLoadingComponent className={className} />
        <DefaultLoadingComponent className={className} />
        <DefaultLoadingComponent className={className} />
      </SliderLoadingContentWrapper>
    </SliderLoadingWrapper>
  );
};
