import React from "react";
import { cn } from "../../../../../helpers/cn";

type SliderLoadingWrapperProps = React.ComponentProps<"div">;

export const SliderLoadingWrapper = ({
  children,
  className,
}: SliderLoadingWrapperProps) => {
  return (
    <div
      className={cn(
        "flex flex-col space-y-3 lg:space-y-6 w-full overflow-hidden",
        className,
      )}
    >
      {children}
    </div>
  );
};
