import { initializeApp } from "firebase/app";
import getConfig from "next/config";
import { IGetConfig } from "../../interfaces/next/IGetConfig";

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: IGetConfig;
};

const firebaseConfig = {
  apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
  authDomain: publicRuntimeConfig.FIREBASE_AUTH_DOMAIN,
  projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
  storageBucket: publicRuntimeConfig.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: publicRuntimeConfig.FIREBASE_MESSAGING_SENDER_ID,
  appId: "1:522202735309:web:b7ec396dba85507b647f84",
};

export const app = initializeApp(firebaseConfig);
