import { cn } from "../../../../../helpers/cn";
import React from "react";

type SliderLoadingContentWrapperProps = React.ComponentProps<"div">;

export const SliderLoadingContentWrapper = ({
  className,
  children,
  ...props
}: SliderLoadingContentWrapperProps) => {
  return (
    <div
      className={cn(
        "flex w-[80rem] justify-start space-x-6 overflow-hidden items-start",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
